<template>
  <div>
    <div class="local-quill-editor">
      <quill-editor
          ref="myLQuillEditor"
          v-model="content"
          :options="editorOption"
          class="editor ql-editor"
          @blur="onEditorBlur"
          @focus="onEditorFocus"
          @change="onEditorChange">
        >
      </quill-editor>
    </div>
    <!--    <div class="xuanfu">-->
    <!--      <el-row>-->
    <!--        <el-col>-->
    <!--          <el-button type="primary" @click="save">保存</el-button>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </div>-->
  </div>

</template>

<script>
// 引入样式和quillEditor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'

// 工具栏配置项
const toolbarOptions = [
  // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  // ['bold', 'italic', 'underline', 'strike'],
  ['bold', 'underline'],
  // 引用  代码块-----['blockquote', 'code-block']
  // ['blockquote', 'code-block'],
  // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  // [{header: 1}, {header: 2}],
  // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  // [{list: 'ordered'}, {list: 'bullet'}],
  // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  // [{script: 'sub'}, {script: 'super'}],
  // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  // [{indent: '-1'}, {indent: '+1'}],
  // 文本方向-----[{'direction': 'rtl'}]
  // [{direction: 'rtl'}],
  // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{size: ['small', false, 'large', 'huge']}],
  // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  // [{header: [1, 2, 3, 4, 5, 6, false]}],
  // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{color: []}, {background: []}],
  // 字体种类-----[{ font: [] }]
  // [{font: []}],
  // 对齐方式-----[{ align: [] }]
  // [{align: []}],
  // 清除文本格式-----['clean']
  ['clean'],
  // 链接、图片、视频-----['link', 'image', 'video']
  // ['image', 'video']
]

export default {
  name: 'LocalQuillEditor',
  // 注册 quillEditor
  components: {
    quillEditor
  },
  props: {
    quillContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: '',
      editorOption: {
        modules: {
          toolbar: toolbarOptions
        },
        theme: 'snow',
        placeholder: '请输入正文'
        // Some Quill optiosn...
      }
    }
  },
  created() {
    this.content = this.quillContent
  },
  methods: {
    // 失去焦点事件
    onEditorBlur(e) {
      // this.$emit('outputVal', e.root.innerHTML)
    },
    // 获得焦点事件
    onEditorFocus(e) {
      // console.log('onEditorFocus: ', e)
    },
    // 内容改变事件
    onEditorChange(e) {

      // console.log('onEditorChange: ', e)
    },
    save() {
      this.$emit('outputVal', this.content)
    },
    /**
     * 获取内容
     * @returns {*}
     */
    getContent() {
      return this.$refs.myLQuillEditor.quill.root.innerHTML
    },
    /**
     * 清除内容
     */
    emptyContent() {
      this.content = ""
    }
  }
}
</script>

<style scoped>
.editor {
  /*height: 400px;*/
}

/deep/ .ql-container, .ql-snow {
  height: 400px;
}

/deep/ .ql-container {
  font-size: 16px;
}

.xuanfu {
  left: 0;
  position: fixed;
  bottom: 10%;
  width: 100%;
  z-index: 100;
}
</style>

