<template>
  <div id="mailbox_edit">
    <el-form ref="form" :model="data" label-width="80px;font-weight: bold;">
      <div style="margin-bottom: 10px">
        <el-input :placeholder="'一封来自' + fnParseTime(currentTime) + '的信'" v-model="data.tpTitle" clearable>
        </el-input>
      </div>
      <!--      <quill @outputVal="getRichText"></quill>-->
      <quill @outputVal="getRichText" :quillContent="data.tpContent" ref="quill"/>
      <div style="margin-top: 10px">
        <el-form-item label="投递时间">
          <el-col :xs="15" :sm="6" :md="4" :lg="3" :xl="1">
            <el-date-picker type="date" placeholder="选择日期" v-model="data.tpTime" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="投递邮箱">
          <el-col :xs="15" :sm="6" :md="4" :lg="3" :xl="1">
            <el-input v-model="data.tpToEmail"></el-input>
          </el-col>
        </el-form-item>
      </div>
      <div>
        <el-row>
          <el-col>
            <el-button type="primary" round @click="addData">投递</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import quill from '@/components/quill/quill'
import {parseTime} from '@/utils';
import ask from "@/request/expand/mailbox/index";
import {isEMailText} from "@/utils/validate";

export default {
  name: "mailbox_edit",
  components: {quill},
  data() {
    return {
      askIndex: new ask(),
      rules: {
        email: [
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ]
      },
      getRichTextContent: '',
      currentTime: new Date(),
      isUpd: false,
      data: {
        tpTitle: '',
        tpTime: new Date(),
        tpToEmail: '',
        tpContent: ''
      }
    }
  },
  methods: {
    getRichText(val) {
      this.data.tpContent = val
    },
    fnParseTime(time) {
      return parseTime(new Date(), '{y}-{m}-{d}')
    },
    addData() {
      if (!isEMailText(this.data.tpToEmail)) {
        this.$notify.error({
          title: '错误',
          message: '请输入正确邮箱'
        });
        return false
      }
      this.data.tpContent = this.$refs.quill.getContent()
      if (this.isUpd) {
        this.askIndex.updTimePostData(this.data).then(res => {
          if (res.code === 'success') {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            });
            this.$router.go(-1)
          }
        })
      } else {
        this.askIndex.insTimePostman(this.data).then(res => {
          if (res.code === 'success') {
            this.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success'
            });
            this.$router.go(-1)
          }
        })
      }
    }
  },
  created() {
    const item = this.$route.params.item
    if (item) {
      this.isUpd = true
      this.data = item
    }
  }
}
</script>

<style scoped lang="less">
#test_Index5 {
  margin-top: 10px;
}
</style>
