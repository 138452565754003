import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";
import axios from "axios";


export default class automaticSpider {
    constructor() {
        this.http = new HttpHandler();
    }
    //ins数据
    insTimePostman(data) {
        const url = '/api/timePostman/insTimePostman'
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    //获取数据
    getTimePostData(data) {
        const url = '/api/timePostman/getTimePostData'
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    timePost(data) {
        const url = '/api/timePostman/timePost'
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    //修改数据
    updTimePostData(data) {
        const url = '/api/timePostman/updTimePostData'
        return this.http.put(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    //发送邮件
    sentEmail(tpId) {
        const url = `/api/timePostman/sentEmail/${tpId}`
        return this.http.post(url, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    //发送邮件
    delTimePostData(tpId) {
        const url = `/api/timePostman/delTimePostData/${tpId}`
        return this.http.delete(url, null, HttpConstant.APPLICATION_JSON_REQ);
    }


}
